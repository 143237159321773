export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/site/pages/index.vue'),
    /* no children */
    meta: {
      "auth": false,
      "layout": "frontend",
      "namespace": "app"
    }
  },
  {
    path: '/admin',
    /* internal name: '/admin' */
    /* no component */
    children: [
      {
        path: '',
        name: '/admin/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/site/pages/admin/index.vue'),
        /* no children */
        meta: {
          "namespace": "admin"
        }
      },
      {
        path: 'feeds',
        /* internal name: '/admin/feeds' */
        /* no component */
        children: [
          {
            path: '',
            name: '/admin/feeds/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/site/pages/admin/feeds/index.vue'),
            /* no children */
            meta: {
              "namespace": "admin"
            }
          },
          {
            path: ':id',
            name: '/admin/feeds/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/site/pages/admin/feeds/[id].vue'),
            /* no children */
            meta: {
              "namespace": "admin"
            }
          }
        ],
        meta: {
          "namespace": "admin"
        }
      },
      {
        path: 'quarry_tasks',
        /* internal name: '/admin/quarry_tasks' */
        /* no component */
        children: [
          {
            path: '',
            name: '/admin/quarry_tasks/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/site/pages/admin/quarry_tasks/index.vue'),
            /* no children */
            meta: {
              "namespace": "admin"
            }
          }
        ],
        meta: {
          "namespace": "admin"
        }
      }
    ],
    meta: {
      "namespace": "admin"
    }
  },
  {
    path: '/channels',
    /* internal name: '/channels' */
    /* no component */
    children: [
      {
        path: ':id',
        name: '/channels/[id]',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/site/pages/channels/[id].vue'),
        /* no children */
        meta: {
          "layout": "app",
          "namespace": "app"
        }
      },
      {
        path: 'new',
        name: '/channels/new',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/site/pages/channels/new.vue'),
        /* no children */
        meta: {
          "layout": "app",
          "namespace": "app"
        }
      }
    ],
    meta: {
      "namespace": "app"
    }
  },
  {
    path: '/dashboard',
    name: '/dashboard',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/site/pages/dashboard.vue'),
    /* no children */
    meta: {
      "layout": "app",
      "namespace": "app"
    }
  },
  {
    path: '/sign_in',
    name: 'sign_in',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/site/pages/sign_in.vue'),
    /* no children */
    meta: {
      "auth": false,
      "layout": "frontend",
      "namespace": "app"
    }
  }
]
