import { defineStore, getActivePinia } from 'pinia'
import { ref, type App } from 'vue'
import { RequestList } from './RequestList'

export const usePageStore = defineStore('Page', () => {
  const app = (getActivePinia() as any)._a as App
  const id = ref(0)
  const aborter = ref(new AbortController())

  function newPage() {
    id.value += 1
  }

  function cleanup() {
    aborter.value.abort()
    aborter.value = new AbortController()
    aRequestList.reset()
  }

  const aRequestList = new RequestList({ $axios: app.config.globalProperties.$axios })

  function requestList() {
    return aRequestList
  }

  return { id, newPage, aborter, cleanup, requestList }
})
