import Axios, { type AxiosInstance } from 'axios'
import { useSSRContext } from 'vue'
import { type App } from 'vue'

declare module 'vue' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance
  }
}

export default function(app: App) {
  const env = app.config.globalProperties.$env

  const axios = Axios.create({
    baseURL: env.API_BASE_URL,
    timeout: 5 * 60 * 1000
  })

  if (env.SSR_AUTHORIZATION) {
    axios.defaults.headers.common.Authorization = env.SSR_AUTHORIZATION
  }

  app.config.globalProperties.$axios = axios
}