import type { App } from 'vue'

declare module 'vue' {
  interface ComponentCustomProperties {
    $env: {
      API_BASE_URL?: string
      SSR_AUTHORIZATION?: string
    }
  }
}

export default function(app: App, config: Record<string, string | undefined>) {
  app.config.globalProperties.$env = {
    API_BASE_URL: config.API_BASE_URL,
    SSR_AUTHORIZATION: config.SSR_AUTHORIZATION
  }
}
