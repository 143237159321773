import type { Account } from '$$/models'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { KlassCache } from './KlassCache'
import type { BaseRequest } from '$$/requests/BaseRequest'
import { useCookies } from '@vueuse/integrations/useCookies'

export const useSessionStore = defineStore('Session', () => {
  const cache = new KlassCache<BaseRequest<any>>()

  const cookies = useCookies(['session_email'])
  const email = computed(() => cookies.get('session_email'))

  function update(account: Account) {
    cookies.set('session_email', account.email)
    // email.value = account.email
  }

  function clear() {
    cookies.remove('session_email')
    // email.value = null
    cache.clear()
  }

  const isSignedIn = computed(() => email.value !== null)
  return { email, update, clear, isSignedIn, cache }
})
