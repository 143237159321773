import "reflect-metadata"
import "$vendor/fontawesome"
import "$vendor/ui/main.css"

import { createSSRApp, createApp } from 'vue'
import App from './App.vue'

export default function(config: Record<string, string | undefined>) {
  // const app = (import.meta.env.SSR) ? createSSRApp(App) : createApp(App)
  const app = createSSRApp(App)

  const initializers = import.meta.glob('./initializers/*.ts', { eager: true })
  for (const initializer of Object.values(initializers)) {
    (initializer as any).default(app, config)
  }

  return app
}

