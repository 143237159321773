<template>
  <div v-if="error">
    {{ error }}
  </div>
  <template v-else>
    <router-view />
  </template>
</template>

<script setup lang="ts">
import { onErrorCaptured, ref } from 'vue'
import { useRouter } from 'vue-router'
import { UnauthorizedError } from './lib/errors';
import { useSessionStore } from './stores/session';

const router = useRouter()
const error = ref(null as null | Error)
const session = useSessionStore()

onErrorCaptured((err) => {
  if (err instanceof UnauthorizedError) {
    session.clear()
    router.push({ name: 'sign_in' })
    return false
  }

  error.value = err
  return false
})

router.afterEach((from, to) => {
  error.value = null
})
</script>
